<template>
<div id="top10" class="big-m-b">

  <div class="xl-m-b">
    <Playlist
      :playlist-position="0" 
      :playlist="profilesWorld"
      :listType="'profiles'"
      :editButtons="false"
      :title="'Top Artists'"
      :subTitle="`Worldwide`"
      :view="'list'"
    />
    <LocationsList
      :dataRef="'locations/rankProfiles'"
      :listType="'profiles'"
    />
    <!-- <LocationsListBig
    :dataRef="'locations/rankProfiles'"
    /> -->
  </div>

  <div class="xl-m-b">
    <Playlist
      :playlist-position="0" 
      :playlist="songsWorld"
      :listType="'songs'"
      :editButtons="false"
      :title="'Top Tracks'"
      :subTitle="`Worldwide`"
      :view="'list'"
    />
    <LocationsList
      :dataRef="'locations/rankSongs'"
      :listType="'songs'"
    />
  </div>

  <div class="xl-m-b">
    <Playlist
      :playlist-position="0" 
      :playlist="genreTechno"
      :listType="'songs'"
      :editButtons="false"
      :title="'Top Techno Tracks'"
      :subTitle="`Worldwide`"
      :view="'grid'"
    />
    <SeeAll
      :dataRef="'genres/rankSongs/Techno'"
      :link="'/music/genre-Techno'"
    />
  </div>

  <div class="xl-m-b">
    <Playlist
      :playlist-position="0" 
      :playlist="genreHipHop"
      :listType="'songs'"
      :editButtons="false"
      :title="'Top HipHop Tracks'"
      :subTitle="`Worldwide`"
      :view="'grid'"
    />
    <SeeAll
      :dataRef="'genres/rankSongs/HipHop'"
      :link="'/music/genre-HipHop'"
    />
  </div>

  <div class="xl-m-b">
    <Playlist
      :playlist-position="0" 
      :playlist="genreElectro"
      :listType="'songs'"
      :editButtons="false"
      :title="'Top Electro Tracks'"
      :subTitle="`Worldwide`"
      :view="'grid'"
    />
    <SeeAll
      :dataRef="'genres/rankSongs/Electro'"
      :link="'/music/genre-Electro'"
    />
  </div>

  <div>
    <GenresList
      :dataRef="'genres/rankSongs'"
    />
  </div>

    
</div>
</template>

<script>
import { rtdb } from '@/db.js'
import { mapGetters } from "vuex"
import Playlist from '@/components/playlists/Playlist.vue'
import LocationsList from '@/components/toplists/LocationsList.vue'
import LocationsListBig from '@/components/music/LocationsListBig.vue'
import GenresList from '@/components/toplists/GenresList.vue'
import SeeAll from '@/components/toplists/SeeAll.vue'

export default {
  name: 'Top10',
  components: {
      Playlist,
      LocationsList,
      LocationsListBig,
      GenresList,
      SeeAll
  },
  data() {
	  return {
        songsWorld: {
          title: 'Top Songs Worldwide',
          songs: null,
        },
        profilesWorld: {
          users: null,
        },
        genreTechno: {
          title: 'Top Songs Techno',
          songs: null,
        },
        genreHipHop: {
          title: 'Top Songs HipHop',
          songs: null,
        },
        genreElectro: {
          title: 'Top Songs Electro',
          songs: null,
        },
	  }
  },
  mounted() {
    this.getSongsWorld()
    this.getProfilesWorld()
    this.getGenreTechno()
    this.getGenreHipHop()
    this.getGenreElectro()
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getUserSettings',
    ])
  },
  methods:{
    getSongsWorld() {
      rtdb.ref('rankLocations/rankSongs/top10/World').once('value', (snapshot) => {
        const arr = []
        const snapChild = snapshot.val()
        snapChild.forEach(x => {
            const songSettings = x.songSettings
            songSettings['rankTotal'] = x.rankTotal
            songSettings['user'] = x.userSettings
            arr.push(songSettings)
        })
        this.songsWorld.songs = arr
      })
    },

    getProfilesWorld() {
      rtdb.ref('rankLocations/rankProfiles/top10/World').once('value', (snapshot) => {
        this.profilesWorld.users = snapshot.val()
      })
    },

    getGenreTechno() {
      rtdb.ref('rankGenres/rankSongs/top10/Techno').once('value', (snapshot) => {
        const arr = []
        const snapChild = snapshot.val()
        snapChild.forEach(x => {
            const songSettings = x.songSettings
            songSettings['rankTotal'] = x.rankTotal
            songSettings['user'] = x.userSettings
            arr.push(songSettings)
        })
        this.genreTechno.songs = arr
      })
    },

    getGenreHipHop() {
      rtdb.ref('rankGenres/rankSongs/top10/HipHop').once('value', (snapshot) => {
        const arr = []
        const snapChild = snapshot.val()
        snapChild.forEach(x => {
            const songSettings = x.songSettings
            songSettings['rankTotal'] = x.rankTotal
            songSettings['user'] = x.userSettings
            arr.push(songSettings)
        })
        this.genreHipHop.songs = arr
      })
    },

    getGenreElectro() {
      rtdb.ref('rankGenres/rankSongs/top10/Electro').once('value', (snapshot) => {
        const arr = []
        const snapChild = snapshot.val()
        snapChild.forEach(x => {
            const songSettings = x.songSettings
            songSettings['rankTotal'] = x.rankTotal
            songSettings['user'] = x.userSettings
            arr.push(songSettings)
        })
        this.genreElectro.songs = arr
      })
    },

    
  }
}
</script>
<style lang="scss" scoped>
</style>