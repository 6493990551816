<template>
  <div id="menu" class="p-f t-0 w-100">
    <div class="d-f tt-up">
        <div class="w-100 h-100vh bc-black-op50" @mouseover="closeMenu(false)"></div>
        <div class="w-100 h-100vh bc-black p-half" @click="closeMenu(false)">
            <h2 class="small-m-t small-m-l small-m-r d-f fd-c">
                <div class="link d-f jc-sb"><router-link class="w-100" to="/">Home</router-link><span>•</span></div>
                <div class="link d-f jc-sb"><router-link class="w-100" to="/about">About</router-link><span>•</span></div>
                <div class="link d-f jc-sb"><router-link class="w-100" to="/artists/world">Artists</router-link><span>•</span></div>
                <div class="link d-f jc-sb"><router-link class="w-100" to="/music/geo-World">Tracks</router-link><span>•</span></div>
                <div v-if="getUser.loggedIn">
                    <br>
                    <div class="link d-f jc-sb"><router-link class="w-100" :to="`/${getUserSettings.usernameUrl}`">Profile</router-link><span>•</span></div>
                    <div class="link d-f jc-sb"><router-link class="w-100 small-m-l" to="/settings">Settings</router-link><span>•</span></div>
                    <div class="link d-f jc-sb"><router-link class="w-100 small-m-l" to="/mymusic">My Tracks</router-link><span>•</span></div>
                    <div class="link d-f jc-sb"><a class="w-100 small-m-l" to="/" @click="logout()" v-if="getUser.loggedIn">Log Out</a><span>•</span></div>
                </div>
                <div v-if="!getUser.loggedIn">
                <br>
                <div class="link d-f jc-sb"><router-link class="w-100" to="/sign_in">Sign In</router-link><span>•</span></div>
                <div class="link d-f jc-sb"><router-link class="w-100" to="/sign_up">Sign Up</router-link><span>•</span></div>
                </div>
                <br>
                <div class="link d-f jc-sb"><router-link class="w-100" to="/legal">Legal</router-link><span>•</span></div>
                <span class="grey">© 2021</span>
            </h2>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    name: 'Menu',
    data () {
        return {
        }
    },
    computed: {
        ...mapGetters([
        'getUser',
        'getUserSettings'
        ]),
    },
    methods: {
        closeMenu(x) {
            return this.$store.commit('SET_MENU_OPEN', x)
        },
        logout() {
            return this.$store.dispatch('logout')
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
#menu {
    z-index: 30;
    .wrap {
        background-color: $brand-bg;
    }
    .link {
        span {display: none;}
        &:hover {span {display: block;}}
    }
}
</style>