<template>
    <div id="logospin" v-show="showLogoSpin">
        <div class="p-f t-0 w-100 h-100vh ai-c bs-bb d-f jc-c" style="pointer-events: none; z-index: -1;">
            <div class="d-f o-2 jc-c w-100 img-big">
                <div class="ani-spin"><PlaceholderImg/></div>
            </div>
        </div>
    </div>
</template>

<script>
import PlaceholderImg from '@/assets/icons/PlaceholderImg.vue'

export default {
    name: 'LogoSpin',
	components: {
		PlaceholderImg
	},
    data () {
        return {
            showLogoSpin: true
        }
    },
}
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
#logospin {
    .ani-spin {
        animation-name: spin;
        animation-duration: 4000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        @keyframes spin { 
            from { 
                transform: rotateY(0deg); 
            } to { 
                transform: rotateY(360deg); 
            }
        }
    }
    @media #{$desktop} {
    }
    @media #{$mobile} {
    }
} 
</style>