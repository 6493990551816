<template>
<div id="see-all">

    <div class="d-f wrap w-100 p-l p-r bs-bb">
        <div class="seeall tt-up ws-pre">
            <router-link class="" :to="{ path: `${link}` }">See all <span class="grey">({{listData.count}})</span></router-link>
        </div>
    </div>

</div>
</template>

<script>
import { rtdb } from '@/db.js'

export default {
  name: 'SeeAll',
  props: [
      'dataRef',
      'link'
  ],
  data() {
	  return {
          listData: {},
	  }
  },
  mounted() {
    this.getCount()
  },
  methods: {
    getCount() {
      rtdb.ref(this.dataRef).once('value', (snapshot) => {
        this.listData = snapshot.val()
      })
    },
  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
#see-all {
  .seeall {
    flex-basis: 20%;
    flex-grow: 1;
    @media #{$mobile} { 
      flex-basis: 100%;
      flex-grow: 0;
    }
  }
  .area, .city, .country {
    flex-basis: 20%;
    flex-grow: 1;
  }
}
</style>