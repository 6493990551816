<template>
  <div id="artists" class="p-f t-0 w-100 h-100">
    <div class="d-f fd-c tt-up">
        
        <div class="w-100 bc-black" @click="closeMenu(false)">
            <div>artists</div>
            <div>artists</div>
            <div>artists</div>
            <div>artists</div>
            <div>artists</div>
            <!-- <h2 class="small-m-t small-m-l d-f fd-c">
                <router-link to="/">Home</router-link>
                <router-link to="/about">About</router-link>
                <br>
                <router-link :to="`/${myProfileUrl}`">Profile</router-link>
                <router-link class="small-m-l" to="/settings">Settings</router-link>
                <router-link class="small-m-l" to="/mymusic">My Music</router-link>
                <a class="small-m-l" to="/" @click="logout()" v-if="getUser.loggedIn">Log Out</a>
                <br v-if="!getUser.loggedIn">
                <router-link to="/sign_up" v-if="!getUser.loggedIn">Sign Up</router-link>
                <router-link to="/sign_in" v-if="!getUser.loggedIn">Sign In</router-link>

                <br>
                <router-link to="/legal">Legal</router-link>
                <span class="grey">© 2021</span>
            </h2> -->
        </div>
        <div class="w-100 h-100 bc-black-op50" @mouseover="closeMenu(false)"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    name: 'Artists',
    data () {
        return {
        }
    },
    computed: {
        ...mapGetters([
        'getUser',
        'getUserSettings'
        ]),
        myProfileUrl() {
            const encodedUsername = encodeURIComponent(this.getUserSettings.username).replace(/\./g, '%2E')
            return encodedUsername
        }
    },
    methods: {
        closeMenu(x) {
            return this.$store.commit('SET_MENU_OPEN', x)
        },
        logout() {
            return this.$store.dispatch('logout')
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
#navbar {
    .wrap {
        background-color: $brand-bg;
    }
}
</style>