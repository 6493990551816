<template>
    <svg xmlns="http://www.w3.org/2000/svg" 
    :aria-labelledby="iconName"
    :viewBox="viewBox"
    :width="w" 
    :height="h">
        <title :id="iconName" lang="en">{{iconName}}</title>
        <g class="stroke" :stroke="color">
        <slot />
        </g>
    </svg>
</template>
<script>
export default {
    props: {
        iconName: { type: String, default: ''},
        viewBox: { type: String, default: '0 0 24 24'},
        w: { type: [Number, String], default: 24 },
        h: {type: [Number, String], default: 24 },
        color: { type: String, default: '#fff'},
    }
}
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.stroke {
    // stroke: $white;
    stroke-width: 2;
    fill: transparent;
    @media #{$mobile} { 
        stroke-width: 1.5; 
    }
}
</style>