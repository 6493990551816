<template>
<div id="locations-list-big" class="p-l p-r big-m-t">

  <h2 class="tt-up">  

    <div class="d-f fd-c jc-c ai-c">
      <div class="filter-wrap d-f ai-c">

        <div class="grey ws-pre o-5">Filter by:</div>

        <!-- <a href="#locations" class="d-f ai-c m-l m-r text-link">         
          <h3 class="d-f jc-c" v-if="geoMatch">
            {{geoMatch}}
          </h3>
          <span class="grey" v-else>
            Location
          </span>
          <IconBase w="24" h="24" color="grey"><IconArrowDown/></IconBase>
        </a> -->
        <a href="#locations" class="d-f ai-c m-l text-link" v-if="geoMatch">         
          <h3 class="d-f jc-c">
            {{geoMatch}}
          </h3>
          <IconBase w="24" h="24" color="grey"><IconArrowDown/></IconBase>
        </a>
        <a href="#locations" class="d-f ai-c m-l" v-else>         
          <span class="grey">
            Locations
          </span>
          <IconBase w="24" h="24" color="grey"><IconArrowDown/></IconBase>
        </a>

        <a href="#genres" class="d-f ai-c m-l text-link" v-if="genreMatch">         
          <h3 class="d-f jc-c">
            {{genreMatch}}
          </h3>
          <IconBase w="24" h="24" color="grey"><IconArrowDown/></IconBase>
        </a>
        <a href="#genres" class="d-f ai-c m-l" v-else>         
          <span class="grey">
            Genre
          </span>
          <IconBase w="24" h="24" color="grey"><IconArrowDown/></IconBase>
        </a>

      </div>
    </div>

  </h2>

  <!-- <h2 class="tt-up ta-j" v-show="dataRef === 'locations/rankSongs'">  
    <span class="grey">Planet </span>
    <span v-for="(x, key) in listData.loc0" :key="x.i d">
        <router-link class="m-r" :to="{ path: `/music/geo-${key}` }" :class="{ underline: isActive(`/music/geo-${key}`) }">Earth<span class="grey ws-pre"> {{x.count}}</span></router-link>
    </span>
    <span class="grey o-5">/ </span>
    <span class="grey">Country </span>
    <span v-for="(x, key) in listData.loc1" :key="x.id">
        <router-link class="m-r" :to="{ path: `/music/geo-${key}` }" :class="{ underline: isActive(`/music/geo-${key}`) }">{{key}}<span class="grey ws-pre"> {{x.count}}</span></router-link>
    </span>      
    <span class="grey o-5">/ </span>
    <span class="grey">City </span>
    <span v-for="(x, key) in listData.loc2" :key="x.id">
        <router-link  class="m-r" :to="{ path: `/music/geo-${key}` }" :class="{ underline: isActive(`/music/geo-${key}`) }">{{key}}<span class="grey ws-pre"> {{x.count}}</span></router-link>
    </span>
    <span class="grey o-5">/ </span>
    <span class="grey">Area </span>
    <span v-for="(x, key) in listData.loc3" :key="x.id">
        <router-link class="m-r" :to="{ path: `/music/geo-${key}` }" :class="{ underline: isActive(`/music/geo-${key}`) }">{{key}}<span class="grey ws-pre"> {{x.count}}</span></router-link>
    </span>
  </h2>

  <h2 class="tt-up ta-j" v-show="dataRef === 'genres/rankSongs'">  
    <span class="grey">Genres </span>
    <span v-for="(x, key) in listData" :key="x.id">
        <router-link class="m-r" :to="{ path: `/music/genre-${key}` }" :class="{ underline: isActive(`/music/genre-${key}`) }">{{key}}<span class="grey ws-pre"> {{x.count}}</span></router-link>
        <span class="grey o-5">/ </span>
    </span>
  </h2> -->

</div>
</template>

<script>
import { rtdb } from '@/db.js'
import IconBase from '@/assets/icons/IconBase.vue'
import IconArrowDown from '@/assets/icons/IconArrowDown.vue'

export default {
  name: 'LocationsListBig',
  props: [
      'dataRef'
  ],
  components: {
      IconBase,
      IconArrowDown
  },
  data() {
	  return {
          listData: {},
          genresList: {},
	  }
  },
  mounted() {
      this.getProfilesList()
      this.getGenresList()
  },
  computed: {
    geoMatch() {
      return this.$route.params.geoMatch
    },
    genreMatch() {
      return this.$route.params.genreMatch
    }
  },
  methods: {
    getProfilesList() {
      rtdb.ref('locations/rankSongs').once('value', (snapshot) => {
        this.listData = snapshot.val()
      })
    },
    getGenresList() {
      rtdb.ref('genres/rankSongs').once('value', (snapshot) => {
        this.genresList = snapshot.val()
      })
    },
    
    isActive(x, xx) {
        return (this.$route.path === x || this.$route.path === xx) ? true : false
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
#locations-list-big {
  .filter-wrap {
  @media #{$mobile} { 
    flex-direction: column;
  }
  } 
}
</style>