<template>
<div v-if="activePlaylist.songs && (activePlaylist.songs.length > 0)">

	<MobilePlayer 
		v-if="isMobile()"
		:userInteractions="userInteractions"
	/>
	<DesktopPlayer 
		v-if="!isMobile()"
		:userInteractions="userInteractions"
	/>
	
</div>
</template>

<script>
import PlayerMixin from '@/mixins/PlayerMixin'
import { isMobileMixin } from '@/mixins/isMobileMixin'
import MobilePlayer from '@/components/player/MobilePlayer'
import DesktopPlayer from '@/components/player/DesktopPlayer'

export default {
	name: 'PersistentPlayer',
	components: {
		MobilePlayer,
		DesktopPlayer,
	},
	mixins: [PlayerMixin, isMobileMixin],
	data() {
		return {
			songPlayed: '',
			userInteractions: {}
		}
	},
	watch: {
	//Watching currentTrackTime of currentTrackId and registers one play after 3 seconds
	//Located here instead of in mixin because the mixin duplicates the action 6 times
		"activePlayer.currentTrackTime"(x) {
			const payload = this.activePlaylist.songs[this.activePlayer.currentTrackId]
			if (x > 2 && this.songPlayed != payload.songUid) {
				this.songPlayed = payload.songUid
	//Update DB with a listen
				this.$store.dispatch('playInteraction', payload)
			} else {
				return
			}
		},
		"activePlayer.currentTrackId"() {
			let payload = this.activePlaylist.songs[this.activePlayer.currentTrackId]
			this.getUserInteractions(payload)
		}
	},
	methods: {
		getUserInteractions(payload) {
			this.$store.dispatch('getUserInteractions', payload).then(response => {
				console.log(response)
				this.userInteractions = response
			}, error => {
				console.error("No user interactions")
			})
		}
	}
}
</script>
