<template>
    <div id="loader" class="p-f t-0 m">
        <h1 class="loading-dots">Loading</h1>
    </div>
</template>

<script>
export default {
    name: 'Loader',
    data () {
        return {
        }
    },
}
</script>
<style lang="scss" scoped>
</style>