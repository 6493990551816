<template>
<div id="playlist-block-grid" class="medium-m-b bs-bb">
            <!-- SONGS -->
            <div class="hover pointer" v-if="isSongs">

                <div 
                class="img-wrapper p-r w-100 hover-br"
                @click="$emit('play-item'); spinCoverFunc()" 
                @mouseenter="toggleHoverFunc" 
                @mouseleave="toggleHoverFunc">
                    <!-- <img :class="{ hovermask : toggleHover }" v-if="img" :src="img"/> -->
                    <img :class="{ hovermask : toggleHover, spinCoverAni : spinCover }" v-if="img" :src="img"/>
                    <div v-else><PlaceholderImg/></div>
                    <div class="p-a b-0" v-show="toggleHover">                                
                        <!-- <h1 class="text-outline">{{(activePlayer.currentTrackId === count) && (activePlaylist.position === playlistPosition) ? 'PLAYING' : 'PLAY'}}</h1> -->
                        <!-- <h1>PLAY</h1> -->
                    </div>
                </div>

                <div class="d-f">
                    <div class="rank-nr">
                        <p class="grey">
                            <span v-if="!editButtons">#</span>
                            <span>{{number}}</span>
                        </p>
                    </div>
                    <div class="m-l">
                            <div class="title-wrap">
                                <div class="d-f fd-c w-100 jc-sb">
                                    <!-- <span class="title" :class="{ underline : ((activePlayer.currentTrackId === count) && (activePlaylist.position === playlistPosition)) || toggleHover}">{{title}}</span>  -->
                                    <!-- <span class="play text-outline">{{(activePlayer.currentTrackId === count) && (activePlaylist.position === playlistPosition) ? 'PLAYING' : 'PLAY'}}</span> -->
                                    <div 
                                    class="title" 
                                    :class="{ underline : toggleHover}" 
                                    @click="$emit('play-item')" 
                                    @mouseenter="toggleHoverFunc" 
                                    @mouseleave="toggleHoverFunc">
                                        {{title}}
                                    </div> 
                                </div>
                                <div>
                                    <router-link 
                                    class="grey text-link-line" 
                                    :to="`/${artistUrl}`">
                                        {{artist}}
                                    </router-link> 
                                </div>
                            </div>
                    </div>
                </div>

            </div>

            <!-- PROFILES -->
            <router-link v-if="isProfiles" :to="`/${song.userSettings.usernameUrl}`">
                <div @mouseenter="toggleHoverFunc" @mouseleave="toggleHoverFunc">
                    <div class="img-wrapper p-r w-100" :class="{ opacity : toggleHover }">
                        <img v-if="img" :src="img"/>
                        <div class="placeholder" v-else><PlaceholderImg/></div>
                    </div>

                    <div class="d-f">
                        <div class="rank-nr">
                            <p class="grey">
                                <span v-if="!editButtons">#</span>
                                <span>{{number}}</span>
                            </p>
                        </div>
                        <div class="m-l">
                            <a class="title-wrap">
                                <p class="d-f w-100 jc-sb">
                                    <span class="title" :class="{ underline : toggleHover}">{{title}}</span> 
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </router-link>
    
    
</div>
</template>

<script>
import PlayerMixin from '@/mixins/PlayerMixin'
import PlaceholderImg from '@/assets/icons/PlaceholderImg.vue'

export default {
    name: 'playlist-block-grid',
    props: ['song', 'count', 'playlistPosition', 'editButtons', 'listType2', 'count'],
    mixins: [PlayerMixin],
	components: {
		PlaceholderImg,
	},
    data() {
        return {
            number: '',
            title: '',
            img: '',
            genre: '',
            artist: '',
            artistUrl: '',
            toggleHover: false,
            spinCover: false
        }
    },
    mounted() {
        this.getData()
    },
    computed: {
      isProfiles() {
          return (this.listType2 === 'profiles') ? true : false;
      },
      isSongs() {
          return (this.listType2 === 'songs') ? true : false;
      }
    },
    methods: {
        getImg(x) {
            if (x) {
                return x
            } else {
                return this.$store.state.placeholderImg
            }
        },  
        getData() {
            if (this.listType2 === 'songs') {
                // this.number = (this.song.rankTotal) ? '#' + this.song.rankTotal : this.count + 1;
                this.number = this.count + 1;
                this.title = this.song.title
                this.img = this.song.cover
                this.genre = this.song.genres.gen1
                this.artist = this.song.artist
                this.artistUrl = this.song.user.usernameUrl
            }
            if (this.listType2 === 'profiles') {
                // this.number = (this.song.rankTotal) ? '#' + this.song.rankTotal : this.count + 1;
                this.number = this.count + 1;
                this.title = this.song.userSettings.username
                this.img = this.song.userSettings.profileImg
            }
        },
        toggleHoverFunc() {
            this.toggleHover = !this.toggleHover;
        },
        spinCoverFunc() {
            this.spinCover = true;
            setTimeout(() => {
                this.spinCover = false
            }, 1500)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";

#playlist-block-grid {

    // a {
        // text-decoration: none;
        // &:hover {
        //     text-decoration: none;
        // }
    // }

    .img-wrapper {
        img {
            width: 100%;
        }
        .placeholder {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            svg {
                background-color: $black;
                padding: 20%;
                // width: 100%;
                // height: 100%;
            }
        }
    }

    @media #{$mobile} { 
        &:nth-child(2n) {
            padding-top: 60px;
        }
    }
    @media #{$desktop} { 
        &:nth-child(2) {
            margin-top: 40px;
        }
        &:nth-child(3) {
            margin-top: 80px;
        }
        &:nth-child(4) {
            margin-top: 120px;
        }
        &:nth-child(5) {
            margin-top: 160px;
        }
    }



    .playlist-track {
    position: relative;
    display: flex;
    .track-duration {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 40;
    }
    .track-duration-hide {
        display: none;
    }
    .buffering-icon {
        position: absolute;
        right: 0;
    }
    }
    .active-color {
    background: rgba(0,0,0, .2);
    }

    .title-wrap {
        text-decoration: none;
        .play {
            display: none;
        }
        &:hover {
            .title {
                // text-decoration: underline;
            }
            .play {
                display: block;
            }
        }
    }

}
</style>
