<template>
  <div>
    <h4 class="">{{playlist.title}}</h4>
    <ol>
      <song-block 
      v-for="(song, key) in playlist.songs" 
      :song="song" 
      :count="key" 
      :key="key" 
      :playlist-position="playlistPosition" 
      :editButtons="editButtons"
      @play-item="$emit('play-select-song', {playerPosition: playlistPosition, trackId: key})">
      </song-block>
    </ol>
  </div>
</template>

<script>
import SongBlock from '@/components/player/SongBlock'

export default {
  name: 'SongsPlaylist',
  components: {SongBlock},
  props: ['playlist', 'playlistPosition', 'editButtons']
}
</script>

<style scoped>
</style>
