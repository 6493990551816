<template>
<div id="locations-list">
    
  <!-- {{listData}} -->

  <div class="d-f wrap w-100 p-l p-r bs-bb" :class="{ aligncenter : alignCenter }">
      <div class="seeall tt-up ws-pre">
        <!-- <p class="grey">World</p> -->
        <div v-for="(x, key) in listData.loc0" :key="x.id">
          <!-- <a>See all <span class="grey">({{x.count}})</span></a> -->
          <router-link class="" :to="{ path: `${getPath}${key}` }">{{key}} <span class="grey">({{x.count}})</span></router-link>
        </div>
      </div>

      <div class="area tt-up m-r">
        <p class="grey">Area</p>
        <div v-for="(x, key) in listData.loc3" :key="x.id">
          <!-- <a class="ws-pre">{{key}} <span class="grey">({{x.count}})</span></a> -->
          <router-link class="" :to="{ path: `${getPath}${key}` }">{{key}} <span class="grey">({{x.count}})</span></router-link>
        </div>
      </div>

      <div class="city tt-up m-r">
        <p class="grey">City</p>
        <div v-for="(x, key) in listData.loc2" :key="x.id">
          <!-- <a class="ws-pre">{{key}} <span class="grey">({{x.count}})</span></a> -->
          <router-link class="" :to="{ path: `${getPath}${key}` }">{{key}} <span class="grey">({{x.count}})</span></router-link>
        </div>
      </div>

      <div class="country tt-up">
        <p class="grey">Country</p>
        <div v-for="(x, key) in listData.loc1" :key="x.id">
          <!-- <a class="ws-pre">{{key}} <span class="grey">({{x.count}})</span></a> -->
          <router-link class="" :to="{ path: `${getPath}${key}` }">{{key}} <span class="grey">({{x.count}})</span></router-link>
        </div>
      </div>
  </div>

</div>
</template>

<script>
import { rtdb } from '@/db.js'

export default {
  name: 'LocationsList',
  props: [
      'dataRef',
      'listType',
      'alignCenter'
  ],
  data() {
	  return {
          listData: {},
	  }
  },
  mounted() {
      this.getProfilesList()
  },
  computed: {
    getPath() {
      if (this.listType === 'songs') {
        return '/music/geo-'
      } else {
        return '/artists/'
      }
    }
  },
  methods: {
    getProfilesList() {
      rtdb.ref(this.dataRef).once('value', (snapshot) => {
        this.listData = snapshot.val()
      })
    },
  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
#locations-list {
  @media #{$mobile} {
    .aligncenter {
      flex-direction: column;
      text-align: center;
      font-size: 1.5em;
    }
  }
  .seeall {
    flex-basis: 20%;
    flex-grow: 1;
    @media #{$mobile} { 
      flex-basis: 100%;
      flex-grow: 0;
      // display: none;
      
    }
  }
  .area, .city, .country {
    flex-basis: 20%;
    flex-grow: 1;
  }
}
</style>