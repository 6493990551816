import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics';
import store from '@/store'

const firebaseConfig = {
    apiKey: "AIzaSyBlL_mIguKK-6uuF22avVhe5QYsjiZK1B0",
    authDomain: "algorhythm-306411.firebaseapp.com",
    databaseURL: "https://algorhythm-306411-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "algorhythm-306411",
    storageBucket: "algorhythm-306411.appspot.com",
    messagingSenderId: "444067960611",
    appId: "1:444067960611:web:ab81c6b35268cb3a695e96",
    measurementId: "G-BM3T9VLBZE"
};
firebase.initializeApp(firebaseConfig)
firebase.analytics() //ADD ONLY TO LIVE DEPLOY
export const fb = firebase
export const fsdb = firebase.firestore()
export const rtdb = firebase.database()
export const auth = firebase.auth()
export const storage = firebase.storage()
// firebase.auth().onAuthStateChanged((user) => {
//     if (user) {
//         store.dispatch('setUser', user);
//         // setUserSettings('setUserSettings', user)
//     } else {
//         store.dispatch('setUser', null);
//     }
// })