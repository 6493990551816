import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import firebase from 'firebase/app'

/* Google Places API START */
import VuePlaceAutocomplete from 'vue-place-autocomplete';
Vue.use(VuePlaceAutocomplete);
/* Google Places API END */

/* PLAYER START */
import XnsSeekBar from 'xns-seek-bar'
Vue.use(XnsSeekBar)
/* PLAYER END */

Vue.config.productionTip = false

// firebase.auth().onAuthStateChanged(user => {
  // store.dispatch('fetchUser', user);
// })

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app');

//This is so that I check onAuthStateChange before mounting the app
const app = new Vue({
  router,
  store,
  render: h => h(App)
})
firebase.auth().onAuthStateChanged(user => {
  if (user) {
    // store.dispatch('setUser', user);
    store.dispatch('fetchUser', user)
    store.dispatch('fetchUserSettings', user)
    store.dispatch('onUserMusicUpdate', user)
    store.dispatch('onUserSettingsUpdate', user)
    app.$mount('#app')
  } else {
    app.$mount('#app')
  }
})

