import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { auth } from '@/db.js'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { authRequired: false, },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { authRequired: false, },
  },
  {
    path: '/legal',
    name: 'LegalPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/LegalPage.vue'),
    meta: { authRequired: false, },
  },
  {
    path: '/artists',
    name: 'ArtistsPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/ArtistsPage.vue'),
    meta: { authRequired: false, },
    children: [
      {
        path: '/artists/:id',
        name: 'ArtistsListId',
        component: () => import('../components/artists/ArtistsPlaylist.vue'),
        meta: { authRequired: false, },
      },
    ]
  },
  {
    path: '/music',
    name: 'MusicPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/MusicPage.vue'),
    meta: { authRequired: false, },
    children: [
      {
        path: '/music/geo-:geoMatch(.*)',
        name: 'MusicGeoListId',
        component: () => import('../components/music/MusicPlaylist.vue'),
        meta: { authRequired: false, },
      },
      {
        path: '/music/genre-:genreMatch(.*)',
        name: 'MusicGenreListId',
        component: () => import('../components/music/MusicPlaylist.vue'),
        meta: { authRequired: false, },
      },
    ]
  },
  {
    path: '/sign_up',
    name: 'sign_up',
    component: () => import('../views/SignUpPage.vue'),
    meta: { authRequired: false, },
  },
  {
    path: '/sign_in',
    name: 'sign_in',
    component: () => import('../views/SignInPage.vue'),
    meta: { authRequired: false, },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    meta: { authRequired: true, },
  },
  {
    path: '/mymusic',
    name: 'MyMusic',
    component: () => import('../views/MyMusicPage.vue'),
    meta: { authRequired: true, },
  },
  {
    path: '/:id',
    name: 'Profile',
    component: () => import('../views/ProfilePage.vue'),
    meta: { authRequired: false, },
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const authRequired = to.matched.some(record => record.meta.authRequired)
  auth.onAuthStateChanged(user => {
    if (authRequired && !user) {
      next({ path: '/sign_in', });
    } else {
      next();
    }
  });
})

export default router