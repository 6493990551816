<template>
  <div class="db-testing red" style="position: fixed; top: 0;">
    DB TESTING IS ON
  </div>
</template>

<script>
import { fsdb, rtdb } from '@/db.js'
export default {
    name: 'DBtesting',
    data () {
        return {
        }
    },
    created() {
      this.rankProfilesGetSort('rankProfiles')
      this.rankProfilesGetSort('rankSongs')
    },
    methods: {

rankProfilesGetSort(x) {
    rtdb.ref(x)
        .orderByChild('ratio')
        // .startAt(0.001)
        .once('value')
        .then((snapshot) => {
            //GET/SORT RankProfiles
            const arr = []
            snapshot.forEach((childSnapshot) => {
                if (x === 'rankSongs') {
                    const obj = {
                        key: childSnapshot.key,
                        userSettings: childSnapshot.val().userSettings,
                        songSettings: childSnapshot.val().songSettings
                    }
                    arr.push(obj)
                } else {
                    const obj = {
                        key: childSnapshot.key,
                        userSettings: childSnapshot.val().userSettings
                    }
                    arr.push(obj)
                }
                
            })
            //REVERSE array
            const revArr = arr.reverse()
            //GET/UPDATE rankTotal
            revArr.forEach((item, index) => {
                // item.val.rankTotal = index + 1
                item.rankTotal = index + 1
            })
            //SUB SORT in to all locations
            //Find and combine duplicate loc1
            function orderBy(param) {           
                //This is because x.param doesn't work. So we have to reduce the string to e.g. get the x.userSettings.locations.loc1 reference
                const nestedRef = (obj, path) => (path.split('.').reduce((value, el) => value[el], obj))
                //We use ...new Set to remove the duplicate values
                const locs = [...new Set(
                    //We map the array to a new array that only contains the values
                    revArr.map((elm) => { return nestedRef(elm, param) })
                )]
                const locsAll = {}
                locs.forEach((loc) => {
                    //DISTRIBUTE localeRank
                    if (loc != undefined) {
                        //We loop over the loc values and filter out revArr based on loc matches
                        let usersByLoc = revArr.filter((elm) => {
                            return nestedRef(elm, param) === loc
                        })
                        //Devide by Top10 and AllUsers
                        let top10 = usersByLoc.slice(0, 10)
                        Object.assign(locsAll, {
                          top10: {
                            ...locsAll.top10,
                            [loc]: top10
                          },
                          allUsers: {
                            ...locsAll.allUsers,
                            [loc]: usersByLoc
                          } 
                        })
                    } else {
                        return
                    }

                })
                // console.log(locsAll);
                return locsAll

            }
            //Write to LocationsRank
            function updateLocations(x) {
                // const vals = ['val.locations.loc0', 'val.locations.loc1', 'val.locations.loc2', 'val.locations.loc3', 'val.locations.loc4', 'val.locations.loc5']
                const vals = ['userSettings.locations.loc0', 'userSettings.locations.loc1', 'userSettings.locations.loc2', 'userSettings.locations.loc3', 'userSettings.locations.loc4', 'userSettings.locations.loc5']
                const resTop10 = {}
                const resAllUsers = {}
                vals.forEach((xx) => {
                    const matchingVals = orderBy(xx)
                    Object.assign(resTop10, matchingVals.top10)
                    Object.assign(resAllUsers, matchingVals.allUsers)
                })
                const res = Object.assign({}, {top10: resTop10}, {allUsers: resAllUsers})
                rtdb.ref('rankLocations')
                .child(x)
                .set(res)
                .then(() => {
                    console.log('rankLocations updated');
                }).catch((err) => {
                    console.log(err);
                })

                //Make new "locations/count" object and write to DB locations
                let locationsObj = {}
                Object.entries(resAllUsers).forEach((xxx)=>{
                    //Find loc key from value
                    let value = xxx['0']
                    let count = xxx['1'].length
                    let object = Object.values(xxx['1'])['0'].userSettings.locations
                    let key = Object.keys(object).find(key => object[key] === value);                   
                    Object.assign(locationsObj, {
                        [key]: {
                            ...locationsObj[key],
                            [value]: {
                                count: count,
                            }
                        } 
                    })
                })
                rtdb.ref('locations')
                .child(x)
                .set(locationsObj)
                .then(() => {
                    console.log('locations count updated');
                }).catch((err) => {
                    console.log(err);
                })

            }
            updateLocations(x)

            
            //Write to GenresRank
            function updateGenres(x) {
                // const vals = ['val.genres.gen1']
                const vals = ['songSettings.genres.gen0', 'songSettings.genres.gen1']
                const resTop10 = {}
                const resAllUsers = {}
                vals.forEach((xx) => {
//PROBLEM: orderBy är location baserat det är därför den här inte fungerar                        
                    const matchingVals = orderBy(xx)
                    Object.assign(resTop10, matchingVals.top10)
                    Object.assign(resAllUsers, matchingVals.allUsers)
                })
                const res = Object.assign({}, {top10: resTop10}, {allUsers: resAllUsers})                
                rtdb.ref('rankGenres')
                .child(x)
                .set(res)
                .then(() => {
                    console.log('rankGenres updated');
                }).catch((err) => {
                    console.log(err);
                })

                //Make new "genres/count" object and write to DB genres
                let genresObj = {}
                Object.entries(resAllUsers).forEach((xxx)=>{
                    //Find loc key from value
                    let value = xxx['0']
                    let count = xxx['1'].length
                    let object = Object.values(xxx['1'])['0'].songSettings.genres
                    let key = Object.keys(object).find(key => object[key] === value);                   
                    Object.assign(genresObj, {
                        ...genresObj[key],
                        [value]: {
                            count: count,
                        }
                    })
                })
                rtdb.ref('genres')
                .child(x)
                .update(genresObj)
                .then(() => {
                    console.log('genres count updated');
                }).catch((err) => {
                    console.log(err);
                })

            }
            if (x === 'rankSongs') {
                updateGenres(x)
            }
            
        })

}


    }

}
</script>