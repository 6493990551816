<template>
<div id="genres-list" class="p-l p-r">

  <h2 class="tt-up ta-c" style="font-weight: 400;" v-show="dataRef === 'genres/rankSongs'">  
    <!-- <span class="grey">Genres</span> -->
    <div v-for="(x, key) in listData" :key="x.id">
        <router-link class="m-r" :to="{ path: `/music/genre-${key}` }" :class="{ underline: isActive(`/music/genre-${key}`) }">{{key}}<span class="grey ws-pre"> ({{x.count}})</span></router-link>
        <!-- <span class="grey o-5"> / </span> -->
    </div>
    <div v-for="(x, key) in emptyListData" :key="x.id">
        <a class="m-r grey" style="text-decoration: none; cursor: default;">{{key}}<span class="grey ws-pre"> (0)</span></a>
        <!-- <span class="grey o-5"> / </span> -->
    </div>
  </h2>

</div>
</template>

<script>
import { rtdb } from '@/db.js'

export default {
  name: 'GenresList',
  props: [
      'dataRef'
  ],
  data() {
	  return {
          listData: {},
          emptyListData: {},
	  }
  },
  mounted() {
      this.getList()
  },
  methods: {
    getList() {
      rtdb.ref(this.dataRef).once('value', (snapshot) => {
        this.listData = snapshot.val()   
        this.getEmptyList(snapshot.val())  
      })
    },
    getEmptyList(x) {
      rtdb.ref('genres/dropdown').once('value', (snapshot) => {  
        const val = snapshot.val()
        Object.keys(x).forEach(i => {
            delete val[i]
        })
        this.emptyListData = val
//TO-DO: remove duplicate keys       
      })
    },
    isActive(x, xx) {
        return (this.$route.path === x || this.$route.path === xx) ? true : false
    }
  }
}
</script>
<style lang="scss" scoped>
</style>