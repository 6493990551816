<template>
  <div>
    <h1>{{title}}</h1>
    <!-- {{list}} -->
    <div v-for="(x, i) in list" :key="i">
        <h2>{{i}}</h2>
        <div v-for="(xx, i) in x" :key="i">
            #{{i + 1}}. {{xx.key}} {{xx}}
            <br>
        </div>
    </div>
  </div>
</template>

<script>
import { rtdb } from '@/db.js'
import { mapGetters } from "vuex"

export default {
  name: 'GetRankList',
  props: ['title', 'listRef', 'childRef'],
  data() {
	  return {
        list: null
	  }
  },
  mounted() {
    this.getList()
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getUserSettings',
    ]),
  },
  methods:{
    getList() {
        if(this.listRef) {
        const ref = rtdb.ref(this.listRef).child(this.childRef).child('top10')
        ref.on('value', (snapshot) => {
            this.list = snapshot.val()    
        })
        }
  },

    
  }
}
</script>
<style lang="scss" scoped>
</style>