<template>
  
  <!-- <li :style="'background'" :class="currentTrackId === count ? 'playlist-track active-color' : 'playlist-track inactive-color'" @click="$emit('play-item')">
    {{count+1 + '. '}}
    <span class="title">{{song.title}}</span>
    - <span class="artist">{{song.artist}}</span> 
    {{currentTrackId === count ? '' : ''}} 
    <span :style="'background: rgba(0,255,0, .5); width: '+(currentTrackTime/currentTrackDuration * 100)+'%;'" :class="currentTrackId === count ? 'track-duration' : 'track-duration-hide'"></span> 
    <span class="buffering-icon" v-if="(currentTrackId === count) && playerIsLoading">
      Loading...
      </span>
  </li> -->
<li :style="'background'" 
:class="(activePlayer.currentTrackId === count) && (activePlaylist.position === playlistPosition) ? 'playlist-track active-color' : 'playlist-track inactive-color'">
    <span class="ws-pre">{{count+1}}. </span>
    <div v-if="song.cover">
      <img :src="song.cover" style="width: 60px; height: 60px;" alt="">
    </div>
    <span @click="$emit('play-item')">{{song.title}}</span>
    <span class="ws-pre"> - </span>
    <span>{{song.artist}}</span> 
  {{(activePlayer.currentTrackId === count) && (activePlaylist.position === playlistPosition) ? '' : ''}} 
  <!-- <span :style="'background: rgba(0,255,0, .2); width: '+(currentTrackTime/currentTrackDuration * 100)+'%;'" :class="(activePlayer.currentTrackId === count) && (activePlaylist.position === playlistPosition) ? 'tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-p-0 tw-m-0 tw-h-full tw-w-full tw-z-30 tw-bg-green-100 tw-h-full' : 'tw-hidden'"></span> -->
  <span class="tw-absolute tw-py-auto tw-pr-2 tw-right-0" 
  v-if="((activePlayer.currentTrackId === count) && (activePlaylist.position === playlistPosition)) && playerIsLoading">
    <BufferingIcon animate="beat" class="tw-text-white tw-cursor-pointer" w="30" h="30" />
  </span> 
  <div v-if="editButtons">
    <span class="ws-pre"> - </span>
    <a @click="deleteSong(song.songUid)">Delete</a>
  </div>
</li>

</template>

<script>
import PlayerMixin from '@/mixins/PlayerMixin'

import BufferingIcon from 'vue-ionicons/dist/ios-play-circle'

export default {
  name: 'SongBlock',
  props: ['song', 'count', 'playlistPosition', 'editButtons'],
  components: {BufferingIcon},
	mixins: [PlayerMixin],
  methods: {

  }
}
</script>

<style lang="scss" scoped>

.playlist-track {
  position: relative;
  display: flex;
  .track-duration {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 40;
  }
  .track-duration-hide {
    display: none;
  }
  .buffering-icon {
    position: absolute;
    right: 0;
  }
}
.active-color {
  background: rgba(0,0,0, .2);
}


</style>
