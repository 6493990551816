<template>
  <div class="tw-flex tw-mx-auto">
    <PersistentPlayer/>
  </div>
</template>

<script>
  import PersistentPlayer from '@/components/player/PersistentPlayer'
  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'Player',
    components: {
        PersistentPlayer
    },
    data(){
      return {
        demoPlaylist: [
          { 
            audio: 'https://rorg.z1.fm/d/3f/ti_ft_eminem_-_thats_all_she_wrote_(zv.fm).mp3', 
            artist: 'T.I', 
            title: 'That\'s All She Wrote (ft. Eminem)', 
            album: '', 
            cover: 'https://res.cloudinary.com/djx5h4cjt/image/upload/v1551189593/random/f55abc725080eb05147e45ce3cd406a8.1000x1000x1.jpg',
            songUid: 'lkjhggh',
            userUid: '897326917',
          },
          { 
            audio: 'https://dll.z1.fm/music/8/e8/ellie_goulding_feat_diplo__swae_lee_-_close_to_me.mp3', 
            artist: 'Ellie Goulding Feat. Diplo & Swae Lee', 
            title: 'Close To Me', 
            album: '', 
            cover: 'https://res.cloudinary.com/djx5h4cjt/image/upload/v1551189716/random/ellie-goulding-close-to-me-lg.jpg',
            songUid: 'jhawjhdgajhga',
            userUid: '123178',
          },
        ]
      }
    },
    computed: {
    ...mapState(['playlists'])
    },
    mounted(){
      // if (this.playlists.length === 0) {
      //   this.addPlaylist({
      //     title: 'Playlist 1',
      //     songs: this.demoPlaylist
      //   }),
      //   this.addPlaylist({
      //     title: 'Playlist 2',
      //     songs: this.demoPlaylist
      //   })
      // } else {

      // }
    },
    methods: {
      ...mapActions(['addPlaylist'])
    }
  }
</script>

<style>
</style>
