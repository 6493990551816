<template>
    <div id="cookie" v-show="showCookieBar && !getUser.loggedIn">
        <div class="p-f b-0 w-100 bs-bb d-f jc-c m-b" style="pointer-events: none;">
            <div class="cookie-box bs-bb bc-brand d-f ai-c jc-c bc-black fd-c" style="pointer-events: auto;">
                <p class="ta-c tt-up w-100 grey">
                    We use 
                    <router-link 
                    :to="'/legal'" 
                    class="text-link grey">
                    cookies</router-link>
                </p>
                <div class="d-f ai-c hover p-half" @click="showCookieBar = false">
                    <!-- <a class="black">OK</a> -->
                    <IconBase w="16" h="16" color="#fff" icon-name="cancel"><IconCancel/></IconBase>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconBase from '@/assets/icons/IconBase.vue'
import IconCancel from '@/assets/icons/IconCancel.vue'
import { mapGetters } from "vuex"

export default {
    name: 'CookieBar',
	components: {
		IconBase,
		IconCancel
	},
    data () {
        return {
            showCookieBar: true
        }
    },
    computed: {
        ...mapGetters([
        'getUser',
        ]),
    },
}
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
#cookie {
    z-index: 9;
    .cookie-box {
        pointer-events: auto;
        width: 100px;
        height: 100px;
        border-radius: 100%;
        p {
            font-size: 12px;
            padding-top: 10px;
        }
        @media #{$desktop} {
            // width: 40vh;
        }
        @media #{$mobile} {
            // width: 100%;
            width: 100px;
            height: 100px;
            border-radius: 100%;
        }
    }
    
} 
</style>