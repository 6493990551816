<template>
  <div id="footer">
      <div class="w-100 h-100vh bc-brand d-f fd-c ai-c jc-c tt-up p bs-bb">
          <div><a @click="scrollToTop()">Back to top</a></div>
          <div><span class="grey">© 2024</span></div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'Footer',
    data () {
        return {
        }
    },
    methods: {
      scrollToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
    }
}
</script>
<style lang="scss" scoped>
</style>